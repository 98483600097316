<template>
  <AppMainWindowContainer
    v-if="user && user.id"
    type="small"
  >
    <template #header>
      <div class="registration__header">
        <h1 class="header-text">
          {{ $t('activation_user.success.activation_account.header') }}
        </h1>
      </div>
    </template>

    <template #center>
      <div class="registration__description">
        <span class="info-text">
          {{ $t('activation_user.success.activation_account.description') }}
        </span>
      </div>
    </template>

    <template #bottom>
      <div class="registration__footer">
        <AppButton
          class="registration__footer__button"
          @click.native="goToLoginPage"
        >
          {{ $t('activation_user.success.activation_account.link.auth') }}
        </AppButton>
      </div>
    </template>
  </AppMainWindowContainer>
  <AppLoader v-else />
</template>

<script>

import AppMainWindowContainer from '@/components/common/layout/AppMainWindowContainer'
import AppButton from '@/components/common/simple/AppButton'
import { routeList } from '@/router/user'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'PageRegistration',
  components: { AppButton, AppMainWindowContainer, AppLoader },
  data () {
    return {
      isSuccess: false,
      user: null,
    }
  },
  created () {
    this.$store.dispatch('users/fetchActivationUserData', this.$route.params.token).then((response) => {
      if (response.data.isActive) {
        this.$router.push({ name: routeList.USER_LOGIN_NAME })
      }
      this.user = response.data
      // http://localhost:8082/user/profile/action/registration/request/confirmation/271177aa84524ef8dd1b87bee81ffdd7
    })
  },
  methods: {
    goToLoginPage () {
      if (this.user && this.user.email) {
        this.$router.push({ name: routeList.USER_LOGIN_NAME, query: { login: this.user.email } })
      } else {
        this.$router.push({ name: routeList.USER_LOGIN_NAME })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .registration
    &__header
      text-align: center
      margin-bottom: 20px

    &__description
      text-align: center
    &__footer
      display: flex
      flex-direction: column
      text-align: center
      align-items: center
      margin-top: 30px
      &__button
        width: 90%
</style>
